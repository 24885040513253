const gtmInit = (): void => {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l != 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  }(window, document, 'script', 'dataLayer', 'GTM-MZLNNMR'));
};

const gtagInit = () => {
  const s = document.createElement('script');
  s.src = 'https://www.googletagmanager.com/gtag/js?id=G-RZ4CTSES0K';
  s.async = true;
  document.body.appendChild(s);

  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() {
    dataLayer.push(arguments);
  };
  gtag('js', new Date());
  gtag('config', 'G-RZ4CTSES0K');
};

const twitterInit = (): void => {
  window.twttr = (function (d, s, id) {
    let js;
    const fjs = d.getElementsByTagName(s)[0];
    const t = window.twttr || {};
    if (d.getElementById(id)) return t;
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://platform.twitter.com/widgets.js';
    fjs.parentNode.insertBefore(js, fjs);
    t._e = [];
    t.ready = function (f) {
      t._e.push(f);
    };
    return t;
  }(document, 'script', 'twitter-wjs'));
};

const facebookInit = () => {
  const s = document.createElement('script');
  s.src = 'https://connect.facebook.net/en_US/sdk.js';
  s.async = true;
  s.defer = true;
  document.body.appendChild(s);
};

const instagramInit = () => {
  const s = document.createElement('script');
  s.src = 'https://platform.instagram.com/en_US/embeds.js';
  s.async = true;
  s.defer = true;
  document.body.appendChild(s);
};

if (typeof window !== 'undefined') {
  setTimeout(() => {
    gtmInit();
    gtagInit();
    twitterInit();
    facebookInit();
    instagramInit();
  }, 1000);
}
